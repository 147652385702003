<template>
  <div class="carousel-container">
    <Carousel :items-to-show-mobile="2.3" :items-to-show="4" :no-arrows="noArrows" :infinity="infinity"
              :items="products">
      <template #default="{item}">
        <div class="product-preview">
          <ProductPreview :unit="item.unit"
                          :slug="item.url"
                          :labels="item.labels"
                          :noDiscount="true"
                          :second-image="item.images.second_image"
                          :discount="item.prices.discount"
                          :another-products="item.anotherColors"
                          :price="item.prices.standard"
                          :name="item.bold_name" :main-image="item.images.image"/>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import ProductPreview from "~/components/molecules/ProductPreview.vue";
import Carousel from "~/components/molecules/Carousel.vue";

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
    noArrows: {
      type: Boolean,
      default: false,
    },
    infinity: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Carousel,
    ProductPreview,
  },
}
</script>

<style scoped lang="scss">
.product-preview {
  max-width: 452px;
  margin-right: 24px;

  @include tablets {
    max-width: 352px;
    margin-right: 8px;
  }

  @include mobile {
    max-width: 252px;
    margin-right: 8px;
  }

  &:deep(.mobile-padding) {
    padding: 0 !important;
  }
}
</style>
